import React from 'react';

import InteractiveMap from '../components/InteractiveMap';

const Home = () => {
  return (
    <div className="flex flex-col flex-grow">
      <InteractiveMap />
    </div>
  );
};

export default Home;