import L from "leaflet";

export const locations = [
  {
    name: "France",
    coordinates: [46.2276, 2.2137],
    stores: null,
    cities: [],
    customIcon: new L.Icon({
      iconUrl: "/go-marker-short.png", // Make sure this path is correct
      iconSize: [60, 90],
      iconAnchor: [30, 100], // Center bottom of the icon
      popupAnchor: [0, -100],
    }),
  },
  {
    name: "Egypt",
    coordinates: [26.8206, 30.8025],
    stores: 4,
    cities: ["Cairo", "Alexandria"],
    customIcon: new L.Icon({
      iconUrl: "/go-marker-short.png", // Make sure this path is correct
      iconSize: [60, 90],
      iconAnchor: [50, 90], // Center bottom of the icon
      popupAnchor: [-20, -90],
    }),
  },
  {
    name: "Georgia",
    coordinates: [42.3154, 43.3569],
    stores: 1,
    cities: ["Tbilisi"],
    customIcon: new L.Icon({
      iconUrl: "/go-marker-long.png", // Make sure this path is correct
      iconSize: [60, 140],
      iconAnchor: [20, 125], // Center bottom of the icon
      popupAnchor: [10, -130],
    }),
  },
  {
    name: "Azerbaijan",
    coordinates: [40.1431, 47.5769],
    stores: 3,
    cities: ["Baku"],
    customIcon: new L.Icon({
      iconUrl: "/go-marker-short.png", // Make sure this path is correct
      iconSize: [60, 90],
      iconAnchor: [20, 110], // Center bottom of the icon
      popupAnchor: [0, -110],
    }),
  },
  {
    name: "Qatar",
    coordinates: [25.3548, 51.1839],
    stores: 5,
    cities: ["Doha"],
    customIcon: new L.Icon({
      iconUrl: "/go-marker-short.png", // Make sure this path is correct
      iconSize: [60, 90],
      iconAnchor: [30, 80], // Center bottom of the icon
      popupAnchor: [0, -80],
    }),
  },
  {
    name: "United Arab Emirates",
    coordinates: [23.4241, 53.8478],
    stores: 6,
    cities: ["Dubai", "Abu Dhabi"],
    customIcon: new L.Icon({
      iconUrl: "/go-marker-long.png", // Make sure this path is correct
      iconSize: [60, 140],
      iconAnchor: [20, 160], // Center bottom of the icon
      popupAnchor: [10, -160],
    }),
  },
  {
    name: "Oman",
    coordinates: [21.4735, 55.9754],
    stores: 1,
    cities: ["Muscat"],
    customIcon: new L.Icon({
      iconUrl: "/go-marker-short.png", // Make sure this path is correct
      iconSize: [60, 90],
      iconAnchor: [10, 90], // Center bottom of the icon
      popupAnchor: [20, -90],
    }),
  },
  {
    name: "French Guiana",
    coordinates: [3.9339, -53.1258],
    stores: 1,
    cities: ["Cayenne"],
    customIcon: new L.Icon({
      iconUrl: "/go-marker-short.png", // Make sure this path is correct
      iconSize: [60, 90],
      iconAnchor: [50, 80], // Center bottom of the icon
      popupAnchor: [-20, -80],
    }),
  },
  {
    name: "Saint Martin",
    coordinates: [18.0731, -63.0822],
    stores: 1,
    cities: ["Marigot"],
    customIcon: new L.Icon({
      iconUrl: "/go-marker-short.png", // Make sure this path is correct
      iconSize: [60, 90],
      iconAnchor: [50, 80], // Center bottom of the icon
      popupAnchor: [-20, -80],
    }),
  },
];
