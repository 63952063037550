import React from 'react';

const About = () => {
  return (
    <div className="flex flex-col w-full">
      <div className="w-full h-[60vh] relative">
        <video
          className="w-full h-full object-cover"
          autoPlay
          loop
          muted
          playsInline
        >
          <source
            src="https://cdn.shopify.com/videos/c/o/v/85f638ee11584269a9280c773e20e748.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className="bg-white w-full">
        <div className="container mx-auto px-4 py-8 max-w-3xl">
          <h1 className="text-3xl font-bold mb-6">About GO Sport</h1>
          <p className="mb-4">
            GO Sport is a globally recognized sports retailer specializing in
            high-performance, multi-branded goods. Recently acquired in April 2023
            by Al Mana, a prominent Qatari conglomerate, in partnership with
            Intersport France, GO Sport continues to expand its reach and impact
            in the sports retail industry.
          </p>
          <p className="mb-4">
            We cater to athletes of all levels, offering a superior selection of
            performance-oriented products and equipment. Whether you're embarking
            on your fitness journey or competing at a professional level, GO Sport
            ensures you have access to the best tools for your sport.
          </p>
          <p className="mb-4">
            Explore our worldwide presence through the interactive map available
            on our homepage and discover more about our extensive range of
            offerings.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;