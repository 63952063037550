// @ts-nocheck
import "./custom-popup.css";

import { Marker, Popup } from "react-leaflet";

import React from "react";

// Ensure you have this CSS file

// Make sure to import the Leaflet CSS in your main CSS file or component
// import 'leaflet/dist/leaflet.css';

interface Location {
  name: string;
  coordinates: [number, number];
  stores: number;
  cities: string[];
}

interface CustomMarkerProps {
  location: Location;
  onClick: (location: Location) => void;
}

// const customIcon = new L.Icon({
//   iconUrl: '/gs-logo.png', // Make sure this path is correct
//   iconSize: [60, 50],
//   iconAnchor: [50, 80], // Center bottom of the icon
//   popupAnchor: [-20, -100],
// });

const CustomMarker: React.FC<CustomMarkerProps> = ({ location, onClick }) => {
  return (
    <Marker
      position={location.coordinates}
      icon={location.customIcon}
      eventHandlers={{
        click: () => onClick(location),
      }}
    >
      <Popup>
        <div className="custom-popup">
          <h2 className="custom-popup-title">{location.name}</h2>
          {location.stores && (
            <p className="custom-popup-text">
              Number of stores: {location.stores}
            </p>
          )}
          {location.cities.length > 0 && (
            <>
              <p className="custom-popup-text">Locations:</p>
              <ul className="custom-popup-list">
                {location.cities.map((city) => (
                  <li key={city} className="custom-popup-list-item">
                    {city}
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </Popup>
    </Marker>
  );
};

export default CustomMarker;
