// @ts-nocheck
import 'leaflet/dist/leaflet.css';

import {
  MapContainer,
  TileLayer,
} from 'react-leaflet';
import React, { useState } from 'react';

import CustomMarker from './CustomMarker';
import Modal from 'react-modal';
import { locations } from '../consts/locations';

// Ensure modal root element is set
Modal.setAppElement("#root");

// Map center and zoom level to get a world view
const center: [number, number] = [30, -3.5]; // Center of the world view
const zoom = 3; // Global zoom level

const bounds = [
  [85, -180],  // North-East corner
  [-85, 180],  // South-West corner
];

const InteractiveMap = () => {
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleMarkerClick = (location: any) => {
    setSelectedLocation(location);
    setIsDialogOpen(true);
  };

  return (
    <div style={containerStyle}>
      <MapContainer
        center={center}
        zoom={zoom}
        minZoom={3}
        maxZoom={6}
        maxBounds={bounds} 
        style={mapStyle}
      >
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          // attribution={`Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012`}
          tileSize={256}
          noWrap={true}
        />
        {locations.map((location) => (
          <CustomMarker
            key={location.name}
            location={location}
            onClick={handleMarkerClick}
          />
        ))}
      </MapContainer>
    </div>
  );
};

const containerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  height: "83vh",
  // marginTop: "3.5rem",
};

const mapStyle: React.CSSProperties = {
  flex: 1,
};

export default InteractiveMap;
