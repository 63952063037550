import { Link, NavLink, Outlet } from "react-router-dom";

import Footer from "./Footer";
import React from "react";

const Layout = () => {
  const activeStyle = "text-[rgb(177,187,30)]";
  const inactiveStyle = "hover:text-[rgb(177,187,30)]";

  return (
    <div className="flex flex-col min-h-screen">
      <header
        className="fixed top-0 left-0 right-0 z-50 bg-gray-800 text-white"
        style={{ background: "#061822", zIndex: 9999 }}
      >
        <div className="container mx-auto px-2 py-2">
          <div className="flex items-center justify-between">
            <Link to="/" className="flex items-center">
              <img
                src="/gs-logo.png"
                alt="GO Sport Logo"
                className="h-12 mr-3"
              />
            </Link>
            <nav>
              <ul className="flex space-x-6">
                <li>
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      isActive ? activeStyle : inactiveStyle
                    }
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/about"
                    className={({ isActive }) =>
                      isActive ? activeStyle : inactiveStyle
                    }
                  >
                    About
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>

      <main className="flex-grow mt-16">
        {/* Adjust this value based on your header height */}
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
